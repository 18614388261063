import VueRouter, { Route } from 'vue-router';
import { Store } from 'vuex';

import { initialRouteState, RouteNames } from '@/routing/config';

import { CollectionType } from '@/enums/collection-type';
import { TitleOrder } from '@/enums/title-order';

import { OfferPresentationType, OfferPresentationTypes, TitleObjectType } from '@/interfaces/titles';
import { getDefaultFilterCollection } from '@/stores/filter.store';

import type { Package } from '@/@types/graphql-types';
import type { Genres, Subgenre } from '@/constants/types';
import type Provider from '@/interfaces/provider';
import type { CollectionStateUrlParams, CollectionStateUrlQuery, FilterCollection } from '@/stores/filter.store';
import type { ExperimentFromUrl } from '@/stores/user.store';
import { Dictionary } from 'vue-router/types/router';
import { ExperimentMap } from './experiment-helper';
import { ScoringProvider } from './scoring-helper';

const { isNavigationFailure, NavigationFailureType } = VueRouter;

const scoringTypes = [
	{
		queryName: 'rating_imdb',
		filterName: ScoringProvider.IMDB,
	},
	{
		queryName: 'votes_imdb',
		filterName: ScoringProvider.IMDB_VOTES,
	},
	{
		queryName: 'tomatoMeter',
		filterName: ScoringProvider.ROTTEN_TOMATOES,
	},
	{
		queryName: 'rating_justwatch',
		filterName: ScoringProvider.JUSTWATCH,
	},
];

/**
 * Local Functions
 */
function getSortedQuery(filters: Partial<FilterCollection>, filterKey: keyof FilterCollection): string {
	return [...((filters[filterKey] || []) as any)].sort().join(',');
}

const isSubgenrePageWithPath = (filters: Partial<FilterCollection>, subgenresList: Subgenre[]) => {
	if ((filters.subgenres || []).length !== 1) return false;

	const subGenreShortName = filters['subgenres']![0];

	// not all subgenres have fullPath urls, those with fewer titles take a parametric url
	const isTvShowPageWithSubgenre =
		filters['content_types']?.includes(TitleObjectType.SHOW) &&
		subgenresList.some(subgenre => subgenre.shortName === subGenreShortName && subgenre.showsUrl);

	const isMoviePageWithSubgenre =
		filters['content_types']?.includes(TitleObjectType.MOVIE) &&
		subgenresList.some(subgenre => subgenre.shortName === subGenreShortName && subgenre.moviesUrl);

	return isTvShowPageWithSubgenre || isMoviePageWithSubgenre;
};

/** Exported Functions */

function calculateUrl(
	router: VueRouter,
	predefinedRoute: string | undefined = undefined,
	collectionType: CollectionType,
	filters: Partial<FilterCollection> = {},
	providersByShortName: Record<string, Provider | Package>,
	genresByShortName: Genres = {},
	subgenresList: Subgenre[] = []
) {
	const routeName = predefinedRoute ? predefinedRoute : getRouteName(collectionType, filters, subgenresList);
	const { params, query } = getUrlParamsFromFilterState(
		routeName,
		filters,
		providersByShortName,
		genresByShortName,
		collectionType,
		router.currentRoute.query,
		subgenresList
	);
	return router.resolve({ name: routeName, params, query }).href;
}

async function applyExperimentFromRoute(route: Route, store: Store<any>): Promise<undefined> {
	const experimentFromUrl = getExperimentFromUrl(route.query, store.state?.experiment?.experiments ?? {});
	if (experimentFromUrl == null) return;

	await store.dispatch('user/applyExperimentFromUrl', experimentFromUrl);

	const search = new URLSearchParams(window.location.search);
	['remove_preferred_experiment', 'preferred_variant', 'snowplow'].forEach(param => search.delete(param));

	const rewriteUrl = `${window.location.origin}${window.location.pathname}/${search.toString()}`;
	const url = rewriteUrl.endsWith('/') ? rewriteUrl.slice(0, -1) : rewriteUrl;

	setTimeout(() => {
		history.replaceState(history.state, '', url);
	}, 500);
}

async function applyRoute(route: Route, store: Store<any>, deferred?: Promise<any>) {
	if (deferred) await deferred;

	const searchSuggesterState = getSearchSuggesterStateFromUrl(route.query as CollectionStateUrlQuery);
	store.commit('searchSuggester/SET_PERSON_ID', searchSuggesterState.person_id || null);
	store.commit('searchSuggester/SET_QUERY', searchSuggesterState.q || '');

	const filterState = getFilterStateFromUrl(
		(route.name as RouteNames) ?? undefined,
		route.params,
		route.query as CollectionStateUrlQuery,
		store.getters['constant/providersByShortName'],
		store.getters['constant/subgenresList']
	);

	// skip if no filter is specified in route
	if (!Object.keys(filterState).length) {
		return;
	}

	const webLocale = store.state.language.webLocale;
	const collectionType = store.getters['routing/activeCollectionType'];

	store.commit('filter/SET_FILTERS', {
		webLocale,
		collectionType,
		filterState: {
			...getDefaultFilterCollection(),
			...filterState,
		},
	});
}

function getExperimentFromUrl(query: CollectionStateUrlQuery, experiments: ExperimentMap) {
	if (!query || !Object.keys(query).length) return;

	let add: ExperimentFromUrl | null = null;
	const setParams = query['preferred_variant'];
	if (setParams) {
		const [setExperiment, setVariant] = setParams ? setParams.split(':') : [];
		const sendSnowplowEvent = 'snowplow' in query ? true : false;
		if (experiments[setExperiment]) {
			add = {
				experiment: setExperiment,
				variant: setVariant,
				sendSnowplowEvent,
			};
		}
	}

	let remove: ExperimentFromUrl | null = null;
	const disableParams = query['remove_preferred_experiment'];
	if (disableParams) {
		const [disableExperiment] = disableParams ? disableParams.split(':') : [];
		remove = {
			experiment: disableExperiment,
		};
	}

	return add || remove ? { add, remove } : undefined;
}

/**
 * translates a filter state (FilterCollection) to a url.
 */
function getUrlParamsFromFilterState(
	routeName: string,
	filters: Partial<FilterCollection> = {},
	providersByShortName: Record<string, Provider | Package>,
	genresByShortName: Genres,
	collectionType: CollectionType,
	routeQuery: Route['query'],
	subgenresList: Subgenre[] = []
) {
	const params: CollectionStateUrlParams = {};
	const query: CollectionStateUrlQuery = {};
	const isPricedropPage = [CollectionType.PRICEDROPS].includes(collectionType);

	// add provider (singular) to params
	if (
		filters.providers &&
		filters.providers.length === 1 &&
		!isPricedropPage // pricedrop pages do not have singular provider URL pages anymore
	) {
		filters.providers
			.map(providerShortName => providersByShortName[providerShortName])
			.filter(Boolean)
			.forEach(provider => {
				// set the single provider param
				params.provider = provider.slug;
			});
	}

	// handles state changes on offers (app.offers.list) pages
	// -> not sure if we really need this still?!

	// rename parameter keys for url matching defined in mappings
	const singularMap = {
		providers: { singular: 'provider', list: providersByShortName },
		genres: { singular: 'genre', list: genresByShortName },
	};

	const isGenrePage =
		collectionType === CollectionType.POPULAR &&
		(filters['genres'] || []).length === 1 &&
		(filters['genres'] || []).includes('eur');

	const keys = Object.keys(filters).filter(key => !!(filters as any)[key]) as (keyof FilterCollection)[];
	keys.forEach((filterKey: keyof FilterCollection) => {
		switch (filterKey) {
			case 'providers':
			case 'genres': // genres are not yet singularly used
				const filterSingularKey = singularMap[filterKey].singular;
				const itemList = singularMap[filterKey].list;

				// add single provider or genre for single provider page or single genre page (which is not implemented yet)
				if ((filters[filterKey] || []).length === 1) {
					(filters[filterKey] || [])
						.map(shortName => itemList[shortName])
						.filter(Boolean)
						.forEach(item => {
							// set the single genre param
							params[filterSingularKey] = item.slug;
						});
				}

				// if filters is not singular, add it to query
				if (
					(filters[filterKey] || []).length !== 0 &&
					((filterKey === 'providers' && isGenrePage) ||
						(filterKey !== 'providers' && !isGenrePage) ||
						(filterKey === 'providers' && isPricedropPage) || // pricedrops page don't have single-provider pages anymore// apply content_type on search view & wat
						(filters[filterKey] || []).length > 1)
				) {
					query[filterKey] = getSortedQuery(filters, filterKey);
				}
				break;
			case 'age_certifications':
			case 'monetization_types':
			case 'presentation_types':
			case 'production_countries':
			case 'exclude_production_countries':
			case 'exclude_genres':
				if ((filters[filterKey] || []).length !== 0) {
					query[filterKey] = getSortedQuery(filters, filterKey);
				}
				break;
			case 'sorting_random_seed':
				if (filters.sort_by === TitleOrder.RANDOM) {
					query[filterKey] = (filters.sorting_random_seed || 0).toString();
				}
				break;

			case 'content_types':
				// apply content_type on search view & watchlist & leaving soon/coming soon (we don't have static urls for those)
				// apply content_type on search view & watchlist (we don't have static urls for those)
				if (
					[
						'app.search.list',
						'app.lists.my-lists',
						'app.lists.tv-show-tracking',
						'app.lists.public-lists',
					].indexOf(routeName) !== -1
				) {
					if ((filters[filterKey] || []).length !== 0) {
						query.content_type = getSortedQuery(filters, filterKey);
					}
				}
				if (isGenrePage && (filters[filterKey] || []).length !== 0) {
					query.content_type = getSortedQuery(filters, filterKey);
				}
				break;
			case 'scoring_filter_types':
				const scoringFilters = filters[filterKey];
				if (scoringFilters) {
					for (const ratingKey in scoringFilters) {
						const rating = scoringFilters[ratingKey];
						const scoring = scoringTypes.find(scoring => scoring.filterName === ratingKey);
						if (scoring) {
							(query as any)[scoring.queryName] = rating.min_scoring_value;
						}
					}
				}
				break;
			//SUBGENRES
			case 'subgenres':
				if (
					routeName.startsWith('app.titles.popular.subgenre') &&
					isSubgenrePageWithPath(filters, subgenresList)
				) {
					const subGenreShortName = filters['subgenres']![0];
					const subGenreParam = subgenresList.find(
						subGenre => subGenre.shortName === subGenreShortName
					)?.slug;
					if (!!subGenreParam) params['subgenre'] = subGenreParam as string;
				} else {
					if ((filters['subgenres'] || []).length !== 0) {
						query[filterKey] = getSortedQuery(filters, filterKey);
					}
				}
				break;
			//SUBGENRES
			default:
				if (filters && (filters as any)[filterKey] !== null) {
					query[filterKey] = (filters as any)[filterKey];
				}
		}
	});

	// handle q, person_id and list_id query parameters
	// these parameters only exist on 'search' collectionTypes pages
	if (collectionType === CollectionType.SEARCH) {
		(['q', 'person_id', 'list_id'] as (keyof CollectionStateUrlQuery)[])
			.filter(key => routeQuery[key])
			.forEach(key => {
				query[key] = routeQuery[key] as string;
			});
	}

	// include list_id in query for public lists and my lists
	if (routeQuery.list_id != null && [CollectionType.PUBLIC_LISTS, CollectionType.MY_LISTS].includes(collectionType)) {
		query.list_id = routeQuery.list_id as string;
	}

	return { params, query: <Dictionary<string>>query };
}

/**
 * translates a url, split up in url params and url query, to a filter state (FilterCollection).
 * usually this function will be called once upon startup to turn the url into filter state.
 */
function getFilterStateFromUrl(
	routeName: RouteNames | undefined,
	params: CollectionStateUrlParams,
	query: CollectionStateUrlQuery,
	providersByShortName: Record<keyof Provider, Provider>[],
	subgenresList: Subgenre[] = []
) {
	// PROVIDERS
	// const state = initialRouteState[routeName] || {};
	const state = routeName == null ? {} : { ...initialRouteState[routeName] };
	if (routeName === 'app.titles.popular.european') {
		state.genres = ['eur'];
		state.age_certifications = [];
		state.content_types = [];
		state.languages = null;
		state.min_price = null;
		state.max_price = null;
		state.monetization_types = [];
		state.presentation_types = [];
		state.providers = [];
		state.release_year_from = null;
		state.release_year_until = null;
		state.scoring_filter_types = null;
		state.timeline_type = null;
		state.sort_by = null;
		state.sort_asc = null;
	}

	if (params.provider) {
		(Object.keys(providersByShortName) as (keyof Provider)[])
			.map(shortName => (providersByShortName as any)[shortName])
			.filter((provider: Provider) => provider.slug === params.provider)
			.forEach((provider: Provider) => {
				state.providers = [provider.shortName];
			});
	} else if (query.providers) {
		state.providers = query.providers
			.split(',')
			.map(shortName => (providersByShortName as any)[shortName])
			.filter(Boolean)
			.map(provider => provider.shortName);
	}
	// SUBGENRES
	if (params.subgenre) {
		state.subgenres = subgenresList
			.filter(subgenre => subgenre.slug === params.subgenre && subgenre.shortName)
			.map(subgenre => subgenre.shortName!);
	}
	// SUBGENRES

	// SINGLE VALUES
	[
		{ key: 'inner_tab', valueType: 'string' },
		{ key: 'tab', valueType: 'string' },
		{ key: 'list_layout', valueType: 'string' },
		{ key: 'min_price', valueType: 'number' },
		{ key: 'max_price', valueType: 'number' },
		{ key: 'min_runtime', valueType: 'number' },
		{ key: 'max_runtime', valueType: 'number' },
		{ key: 'release_year_from', valueType: 'number' },
		{ key: 'release_year_until', valueType: 'number' },
		{ key: 'timeline_type', valueType: 'string' },
		{ key: 'sort_by', valueType: 'string' },
		{ key: 'sort_asc', valueType: 'boolean' },
		{ key: 'event_date', valueType: 'string' },
		{ key: 'providers_type', valueType: 'string' },
	].forEach(item => {
		if (item.key in query) {
			const queryValue = query[item.key as keyof CollectionStateUrlQuery] || '';
			let value;

			switch (item.valueType) {
				case 'number':
					value = parseInt(queryValue);
					break;

				case 'boolean':
					value = queryValue === 'true';
					break;

				case 'string':
				default:
					value = queryValue;
					break;
			}

			(state as any)[item.key] = value;
		}
	});
	// CONCATENATED VALUES
	(
		[
			'age_certifications',
			'monetization_types',
			'production_countries',
			'exclude_production_countries',
			'subgenres',
		] as (keyof CollectionStateUrlQuery)[]
	).forEach(key => {
		if (key in query) {
			const queryValue = (query as any)[key];
			(state as any)[key] = Array.isArray(queryValue) ? queryValue : (query as any)[key].split(',');
		}
	});

	// Temporary fix to filter genres to exclude 'none' and other strings
	(['genres', 'exclude_genres'] as (keyof CollectionStateUrlQuery)[]).forEach(key => {
		if (key in query) {
			const queryValue = (query as any)[key];
			(state as any)[key] = Array.isArray(queryValue)
				? queryValue
				: queryValue.split(',').filter((genre: string) => genre.length === 3);
		}
	});

	// SPECIAL VALUES (presentation_types)
	if (query?.presentation_types != null) {
		const validPresentationTypes = new Set(OfferPresentationTypes.map(item => item.value));
		state.presentation_types = Array.isArray(query.presentation_types)
			? query.presentation_types
			: (query.presentation_types.split(',') as OfferPresentationType[]).filter(type =>
					validPresentationTypes.has(type)
			  );
	}
	// SPECIAL VALUES (scoring_filter_types)
	const scoringFilterTypes = scoringTypes
		.map(key => key.queryName)
		.filter(key => key in query)
		.reduce((root: any, key) => {
			const scoring = scoringTypes.find(scoring => scoring.queryName === key);
			const scoringKey = scoring ? scoring.filterName : '';
			const scoringValue = parseFloat((query as any)[key]);
			root[scoringKey] = { min_scoring_value: scoringValue };
			return root;
		}, {});

	if (Object.keys(scoringFilterTypes).length) {
		(state as any)['scoring_filter_types'] = Object.keys(scoringFilterTypes).length > 0 ? scoringFilterTypes : null;
	}
	//drop sort_by filter as it is incorrect one(like for tomato_score)
	const sortBy = (state as any)['sort_by'];
	if (sortBy && !Object.values(TitleOrder).includes(sortBy as TitleOrder)) {
		(state as any)['sort_by'] = null;
		(state as any)['sort_asc'] = null;
	}

	// handle `q` and `person_id` parameters, remove them if they're not present
	// [{ name: 'q', value: query.q }, { name: 'person_id', value: parseInt(query.person_id || '', 10) }].forEach(
	// 	item => {
	// 		if ((query as any)[item.name]) {
	// 			(state as any)[item.name] = item.value;
	// 		} else {
	// 			delete (state as any)[item.name];
	// 		}
	// 	}
	// );

	if (query.content_type) {
		state.content_types = [query.content_type as TitleObjectType];
	}

	return state;
}

function getRouteName(
	collectionType: CollectionType,
	filters: Partial<FilterCollection> = {},
	subgenresList: Subgenre[] = []
): string {
	switch (collectionType) {
		case CollectionType.SEARCH:
			return 'app.search.list';
		case CollectionType.MY_LISTS:
			return 'app.lists.my-lists';
		case CollectionType.TV_SHOW_TRACKING:
			return 'app.lists.tv-show-tracking';
		case CollectionType.PUBLIC_LISTS:
			return 'app.lists.public-lists';
		case CollectionType.OFFERS:
			return 'app.offers.list';
		case CollectionType.TITLELIST:
			return 'app.landingpage.curatedlist';
		case CollectionType.EDITORIAL:
			return 'app.editorial.article';
		case CollectionType.SPORTS:
			return 'app.sports.all';
		case CollectionType.SUBGENRE:
		case CollectionType.UPCOMING:
		case CollectionType.POPULAR:
			if (filters.genres?.length === 1 && filters.genres?.includes('eur')) {
				return 'app.titles.popular.european';
			}
		default:
			const contentTypeFilter =
				(filters.content_types || [])[0] === TitleObjectType.MOVIE
					? TitleObjectType.MOVIE
					: TitleObjectType.SHOW;

			const hasOnlyOneProvider = (filters.providers || []).length === 1;
			const hasOnlyOneContentType = (filters.content_types || []).length === 1;

			if (hasOnlyOneProvider && ![CollectionType.PRICEDROPS].includes(collectionType)) {
				if (!hasOnlyOneContentType) {
					return `app.titles.${collectionType}.provider`;
				} else {
					return `app.titles.${collectionType}.provider-${contentTypeFilter}`;
				}
			} else {
				// Has 0 or more than 1 providers
				if (!hasOnlyOneContentType) {
					if (isSubgenrePageWithPath(filters, subgenresList)) {
						return `app.titles.popular.subgenre`;
					}
					return `app.titles.${collectionType}.list`;
				} else {
					if (isSubgenrePageWithPath(filters, subgenresList)) {
						return `app.titles.popular.subgenre-${contentTypeFilter}`;
					}
					return `app.titles.${collectionType}.${contentTypeFilter}`;
				}
			}
	}
}

function getSearchSuggesterStateFromUrl(query: CollectionStateUrlQuery) {
	return (['q', 'person_id'] as (keyof CollectionStateUrlQuery)[])
		.filter(key => query[key])
		.reduce((root, key) => {
			root[key] = query[key];
			return root;
		}, {} as CollectionStateUrlQuery);
}

function updateState(
	router: VueRouter,
	predefinedRoute: string | undefined = undefined,
	collectionType: CollectionType,
	filters: Partial<FilterCollection> = {},
	providersByShortName: Record<string, Provider | Package>,
	genresByShortName: Genres,
	routeQuery: Route['query'] = {},
	subgenresList: Subgenre[] = []
) {
	let routeName = predefinedRoute ? predefinedRoute : getRouteName(collectionType, filters, subgenresList);
	if (routeName === 'app.titles.popular.provider-plans' && (filters?.providers?.length || 0) > 1) {
		routeName = 'app.titles.popular.list';
	}
	const { params, query } = getUrlParamsFromFilterState(
		routeName,
		filters,
		providersByShortName,
		genresByShortName,
		collectionType,
		routeQuery,
		subgenresList
	);

	// Note(igor): To avoid NavigationDuplicated exception, catch it and ignore
	// Exception comes in cases the same URL is used and only query is updated
	router.push({ name: routeName, params, query }).catch((error: any) => {
		if (
			!isNavigationFailure(error, NavigationFailureType.duplicated) &&
			!isNavigationFailure(error, NavigationFailureType.cancelled)
			// Note(valerio.mazza) Search.vue component generates a navigation canceled due to
			// a reset of the filters which sometimes triggers a navigation
		) {
			throw error;
		}
	});
}

export {
	applyExperimentFromRoute,
	applyRoute,
	calculateUrl,
	getFilterStateFromUrl,
	getRouteName,
	getUrlParamsFromFilterState,
	updateState,
};
